









































import Vue from "vue";
import { Component } from "vue-property-decorator";
import ManagePdf from "@/components/ManagePdf.vue";
import Comments from "@/components/Comments.vue";
import { CommentType } from "@/models/comment";

@Component({
  components: {
    ManagePdf,
    Comments,
  },
})
export default class ViewDocument extends Vue {
  commentType = CommentType.Document;
  showDialog = false;
}
